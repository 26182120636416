import 'promise/polyfill';

import React from 'react';
import PropTypes from 'prop-types';

import createHistory from 'history/createBrowserHistory';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
// import { push } from 'react-router-redux';
import { oauthTokenSuccess } from './actions/';
import { getAuthService } from './api/auth-service';
import { decodeURLParams } from './utils/decoders';
import sagaWatchers from './sagas/watchers-saga';

import configureStore from './store';
import Routes from './routes';

import { logger } from './utils';

// @TODO move it out to localStorage tand ydrate from there.
const initialState = {
  api: {},
  oauth: {}
};

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();

const store = configureStore(initialState, history, sagaMiddleware);

// store.close = () => store.dispatch(END)
// history = syncHistoryWithStore(history, store);
// history.listen(location => console.log('Navigated to --> ' + location));
// const state = store.getState();

sagaMiddleware.run(sagaWatchers);

class Iam extends React.Component {

  static defaultProps = {
    unprotected: [
      '/ui/iam/providers',
      '/ui/iam/login',
      '/ui/iam/identities/addserviceaccount',
      '/ui/iam/change-password']
  }

  constructor(props) {
    super(props);
    this.state = {
      sessionInfoFetched: false
    };
  }

  componentDidMount() {
    // check and route to login page or providers page.
    logger('check and route to login page or providers page.');
    this.handleLocation();
  }

  /**
   * Check if a path is protected.
   * @param {String} path URI path fragment
   * @returns {Boolean} a boolean if path is protected
   */
  isUnprotected(path) {
    return this.props.unprotected.indexOf(path) > -1;
  }
  handleLocation() {
    if (window.location.hash.indexOf('access_token') > -1) {
      const uri = window.location.hash.substr(1);
      const params = decodeURLParams(uri);
      let response = {
        access_token: '',
        expires_in: 0,
        id_token: '',
        state: '',
        token_type: ''
      };
      response = Object.assign(response, params);
      logger(`Redirect params::  ${JSON.stringify(params)}`);
      // @TODO check feasibility of a middleware for this for now march on
      localStorage.setItem('id_tokenauth_response', JSON.stringify(response));
      store.dispatch(oauthTokenSuccess({ ...response }));
      history.push('/identities');
    } else if (!this.isUnprotected(window.location.pathname)) {
      // check if user is authentocated here and if not redirect then to force a login.
      getAuthService().login();
      // store.dispatch(push('/forcelogin'));
    } else {
      logger('What do we do now!');
      // store.dispatch(push('/users'));
    }
  }

  render() {
    return (
      <Provider store={ store } >
        <Routes history={ history } />
      </Provider>
    );
  }

}

Iam.propTypes = {
  unprotected: PropTypes.array
};

export default Iam;
