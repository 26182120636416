import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, FormItemInput } from 'prism-reactjs';

// TO-DO: CHANGE LATER TO USE ntnx-I18n library
import getI18nService from '../../i18n/I18n';
import './LoginPage.less';

const i18n = getI18nService();

class MyInput extends React.Component {

  static propTypes = {
    rowsData: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    isIDPSearch: PropTypes.func
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      rowsData: props.rowsData
    };
  }

  /**
   * Handles inputs change to set values in state
   * @param {Object} evt - Input event.
   */
  handleInputChange = (evt) => {
    const { value, name } = evt.target;

    if (name === 'login') {
      this.saveUsernameToSession(value);
      this.setState({
        [name]: value,
        showError: false
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleOnClick = () => {
    const { name } = this.state;
    this.props.isIDPSearch(name);
    this.props.apiFetchRequest(
      {
        endpoint: 'GET_CONNECTORS',
        body: {
          name
        }
      }
    );
  }

  render() {
    const connector = this.state.selectedRow || {};

    return (
      <React.Fragment>
        <FormItemInput
          id="idpName"
          data-test="loginIdpName"
          value={ this.state.name }
          label={ i18n.t('Providers.name') }
          name="name"
          onChange={ this.handleInputChange }
        />
        <Button
          fullWidth={ true }
          data-test={ 'providersButtonIDPLogin' }
          id={ connector.id || 1 } key={ 1 }
          disabled={ !this.state.name }
          onClick={
            this.handleOnClick
          }
        >
          <p className="overflow-ellipsis">
            {i18n.t('Providers.loginWithProvider')}
          </p>
        </Button>
      </React.Fragment>
    );
  }

}

export default MyInput;
