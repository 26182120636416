/**
  * Extract login Custom texts and colors out of array
  * @param {Array} array - long or short version string.
  * @return {Object} - customLogin key and value
*/
export const getLoginCustomObj = (array) => {
  const customLoginObj = {};
  if (array && array.length) {
    for (const obj of array) {
      const { key, value } = obj;
      if (!customLoginObj[key]) {
        customLoginObj[key] = value;
      }
    }
  }
  return customLoginObj;
};
